<template>
  <div id="tradingView">
    <div class="content_box">
      <div class="inner_max">
        <div class="logo_wrapper d-flex justify-content-center mb-5">
          <img src="@/assets/images/tradingViewLogo.png" alt />
        </div>
        <p class="subtitle text-center mb-4">{{ $t('tradingView.subTitle') }}</p>
        <div class="bg-white p-3 p-md-5 rounded">
          <div class="box mb-5">
            <div class="d-flex justify-content-center mb-2">
              <el-row :gutter="20" class="list_wrapper mb-2 mb-md-3">
                <el-col :xs="24" :sm="12">
                  <el-button data-testid="login2" class="mb-2" @click="goToTradingView">
                    {{ $t('common.button.login') }}
                  </el-button>
                </el-col>
                <el-col :xs="24" :sm="12">
                  <el-button data-testid="learn2" type="primary" class="mb-2" @click="goToTnc">
                    {{ $t('tradingView.learnMore') }}
                  </el-button>
                </el-col>
              </el-row>
            </div>
            <div class="image_wrapper d-flex justify-content-center mb-3 mb-md-5">
              <img src="@/assets/images/tradingView.png" alt />
            </div>
            <p v-html="$t('tradingView.intro')" class="text-primary mb-3 mb-md-5 text-center"></p>
            <el-row :gutter="50" class="list_wrapper mb-5">
              <el-col
                :xs="24"
                :sm="item.span"
                :offset="!isMobile && item.key % 2 !== 0 ? 3 : 1"
                v-for="item in descpList"
                :key="item.key"
              >
                <div class="list_item_wrapper">
                  <i class="el-icon-circle-check text-primary"></i>
                  <div class="list_item text-primary d-inline">{{ item.descp }}</div>
                </div>
              </el-col>
            </el-row>
            <div class="d-flex justify-content-center d-flex justify-content-center mb-2 mb-2">
              <el-row :gutter="20" class="list_wrapper mb-3">
                <el-col :xs="24" :sm="12">
                  <el-button data-testid="login2" class="mb-2" @click="goToTradingView">
                    {{ $t('common.button.login') }}
                  </el-button>
                </el-col>
                <el-col :xs="24" :sm="12">
                  <el-button data-testid="learn2" type="primary" class="mb-2" @click="goToTnc">
                    {{ $t('tradingView.learnMore') }}
                  </el-button>
                </el-col>
              </el-row>
            </div>
            <div class="image_wrapper d-flex justify-content-center mb-5">
              <img src="@/assets/images/computer.png" alt />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'tradingView',
  data() {
    return {
      isMobile: false,
      descpList: [
        { key: 1, descp: this.$t('tradingView.descp1'), span: 10 },
        { key: 2, descp: this.$t('tradingView.descp2'), span: 10 },
        { key: 3, descp: this.$t('tradingView.descp3'), span: 10 },
        { key: 4, descp: this.$t('tradingView.descp4'), span: 10 },
        { key: 5, descp: this.$t('tradingView.descp5'), span: 12 }
      ]
    };
  },
  computed: {
    lang() {
      return this.$store.state.common.lang;
    },
    tradingViewUrl() {
      return this.lang === 'zh_CN' ? this.GLOBAL_DOMAIN_WEBSITE + '/zh-AU/' : this.GLOBAL_DOMAIN_WEBSITE;
    }
  },
  methods: {
    goToTradingView() {
      let langSuffix = this.$options.filters.tradingViewLangSwitch(this.lang);
      window.open('https://charts.vantagemarkets.com/au?lang=' + langSuffix, '_blank');
    },
    goToTnc() {
      window.open(`https://${this.tradingViewUrl}/platforms/trading-platforms/charts-by-tradingview/`, '_blank');
    },
    getMedia() {
      this.isMobile = window.matchMedia('(max-width: 767px)').matches;
    }
  },
  mounted() {
    window.addEventListener('resize', this.getMedia);
    this.getMedia();
  },
  beforeDestroy() {
    // Unregister the event listener before destroying this Vue instance
    window.removeEventListener('resize', this.getMedia);
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/tradingView.scss';
</style>
